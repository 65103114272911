<template>
    <div class="container">
         <div class="header-space"></div>   
        <svg version="1.1" id="kjlogo" xmlns:x="&ns_extend;" xmlns:i="&ns_ai;" xmlns:graph="&ns_graphs;"
                 xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 936 703.06"
                 style="enable-background:new 0 0 936 703.06;" xml:space="preserve">
            <switch>
            
                <g i:extraneous="self">
                    <path id="j" d="M879.53,353.69c-7.28-21.94-15.5-43.37-29.02-62.16c-27.46-38.15-55.63-75.79-83.26-113.81
                        c-12.78-17.58-24.99-35.57-37.44-53.4c-3.71-5.31-3.22-10.47-0.43-16.29c5.37-11.2,11.09-22.42,14.61-34.25
                        c3.42-11.49-1.94-21.35-10.94-29.04c-8.18-6.98-17.3-5.66-25.73-1.55c-8.41,4.1-16.75,8.97-23.87,14.98
                        c-15.21,12.84-29.92,26.33-44.29,40.11c-36.03,34.57-73.83,67.1-112.25,98.94c-19.56,16.22-39.05,32.54-54.4,53.12
                        c-4.35,5.83-7.85,11.87-8.69,19.28c-2.27,19.81,15.81,35.06,34.49,28.59c11.18-3.87,21.93-9.01,33.1-12.93
                        c12.42-4.35,17.93-2.71,25.96,7.49c13.26,16.86,26.34,33.87,39.23,51.01c24.03,31.98,48.13,63.92,71.7,96.25
                        c14.33,19.65,28.22,39.66,41.25,60.17c4.57,7.2,6.63,16.25,8.65,24.73c1.32,5.53-1.47,10.74-6.21,14.32
                        c-4.63,3.5-8.3-0.15-12.15-1.94c-1.22-0.57-2.21-1.62-3.41-2.28c-9.83-5.37-19.66-9.17-30.31-1.61
                        c-17.13,12.16-29.77,27.85-36.85,47.6c-5.51,15.36-8.72,31.34-9.09,47.74c-0.31,13.66,0.54,27.13,8.62,38.96
                        c6.81,9.96,16.02,15.55,28.57,16.26c5.99-1.56,12.08-2.79,17.94-4.73c15.32-5.08,28.62-13.96,42.01-22.71
                        c15.57-10.18,30.68-20.91,43.97-33.98c31.45-30.95,59.57-64.46,82.72-102.26c17.69-28.87,31.14-59.28,37.82-92.3
                        C886.85,403.33,887.74,378.44,879.53,353.69z"/>
                    <path id="k" d="M552.35,637.72c-1.28-2.73-3.29-5.1-4.78-7.75c-35.52-63.11-71.01-126.24-106.5-189.36
                        c-23.64-42.04-47.27-84.07-70.93-126.1c-2.02-3.59-1.44-6.78,0.58-10.28c18.58-32.13,37.1-64.3,55.53-96.52
                        c21.23-37.1,42.34-74.27,63.52-111.4c10.93-19.16,21.91-38.29,32.85-57.44c2.64-4.63,0.86-7.75-4.47-7.81
                        c-2.43-0.03-4.87,0.19-7.3,0.19c-59.97,0-119.93-0.02-179.9-0.03c-48.1-0.01-96.2,0-144.31,0c0-0.03,0-0.07,0-0.1
                        c-49.78,0-99.55,0.01-149.33-0.02c-4.39,0-6.49,2.05-6.48,6.36c0.01,5.48,0.07,10.96,0.07,16.44
                        c-0.02,155.25-0.05,310.5-0.08,465.75c-0.01,35.77-0.01,71.54-0.02,107.31c0,3.65-0.21,7.31-0.06,10.95
                        c0.16,3.96,2.28,6.13,6.41,6.1c4.26-0.03,8.52,0.02,12.78,0.02c157.4,0,314.8,0,472.19-0.01c8.66,0,17.32-0.07,25.98-0.05
                        C552.25,643.96,554.12,641.48,552.35,637.72z M460.4,584.31c-64.38,0.01-128.76,0.03-193.14,0.04c-4.72,0-9.44,0-14.16,0.03
                        c-7.68,0.04-8.8-1.07-8.8-8.59c0.01-36.68-0.01-73.37,0.01-110.05c0-5.02,0.17-10.04,0.2-15.06c0.03-5.69-2.02-7.68-7.69-7.63
                        c-2.74,0.03-5.48,0.06-8.21-0.02c-6.17-0.16-8.84,2.44-8.84,8.78c0,13.09,0.02,26.18,0.03,39.27c0.03,28.46,0.06,56.93,0.09,85.39
                        c0.01,6.56-1.42,7.94-8.12,7.94c-38.66,0-77.33,0-115.99,0c-7.05,0-8.38-1.3-8.38-8.13c0.01-79.3,0-158.61,0-237.91
                        c0.09,0,0.19,0,0.28,0c0-77.32,0.13-154.65-0.1-231.97c-0.05-16.69,1.08-13.9,14.16-13.91c37.14-0.03,74.28-0.09,111.42-0.22
                        c4.68-0.02,6.64,2.09,6.63,6.62c-0.04,45.05-0.01,90.11-0.09,135.16c-0.02,10.28,1.26,11.75,11.38,11.6
                        c13.52-0.21,13.37-0.21,13.35-13.74c-0.07-44.29-0.02-88.59-0.06-132.88c0-4.33,1.27-6.83,6.26-6.82
                        c56.01,0.15,112.03,0.19,168.04,0.24c3.8,0,7.61-0.27,11.4-0.09c1.82,0.08,4.53,0.4,5.17,1.54c0.69,1.24-0.12,3.82-1.04,5.37
                        c-7.68,12.94-15.44,25.84-23.35,38.64c-23.85,38.55-47.81,77.03-71.67,115.58c-10.89,17.59-21.74,35.21-32.25,53.02
                        c-1.14,1.94-0.84,5.83,0.37,7.88c27.35,46.39,55.02,92.58,82.41,138.94c23.06,39.02,45.86,78.21,68.8,117.31
                        c1.61,2.75,3.68,5.24,5.19,8.04C465.65,582.29,464.35,584.31,460.4,584.31z"/>
                </g>
            </switch>
            
        </svg> 
        
        <div id="m-about" class="main">
            <h1>About Us</h1>
           <p>
               Since we started this company, 
               we strive for the people satisfaction, 
               and we will continue till the end of time.
             </p> 
        </div> 
    </div>
</template>

<script>
export default {
    name: 'AboutUs'

}
</script>
<style scoped>
#kjlogo{
    position: absolute;
    width: 40px;
    height: 35px;
    fill: white;
    background-color: black;
    margin-top: 5px;
    right: 10px;
    border-radius: 7px;
    border: 2px solid white;
    padding: 1px;
    animation: kmove 1.5s ease-in-out forwards;
    animation: kmove 2.5s ease-in-out backwards;
    
}

#k{
   animation: kmove 1.5s ease-in-out;
}

@keyframes kmove{
    0%{
        fill: red;
    }
    25%{
        fill:orange;
        transform: rotate((-55deg));
        border: 2px dashed greenyellow;
    }
    50%{
        fill: white;
        transform: rotate(-90deg);
        border: 1.5px groove white;
    }
    75%{
        fill:orange;
        transform: rotate((40deg));
        border: 3px double red;
    }
    100%{
        fill: black;
        stroke: white;
        transform: rotate(0);
        border: 3px dotted blue;
    }
}
#j{
    animation: jmove 1.5s ease-in-out;
}

@keyframes jmove{
    0%{
        fill: greenyellow;
    }
    25%{
        fill:yellow;
        transform: rotate((55deg));
        width: 20px;
    }
    50%{
        fill: white;
        transform: rotate(90deg);
        width: 50px;
     
    }
    75%{
        fill:orange;
        transform: rotate(-(55deg));
        width: 60px;
     
    }
    100%{
        fill: white;
        transform: rotate(0);
     

    }
}
</style>