<template>
  <div class="container">
    <HomePage />
  </div>
</template>

<script>

import HomePage from '@/views/HomePage.vue';

export default {
  name: 'App',
  components: {
    HomePage
}
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;

} */
.header-space{
  margin-top: 4rem;
}
</style>
